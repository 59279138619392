.sidebar {
  width: 30%;
  min-width: 16em;
  float: left;
  @media (max-width: 60em) {
    width: 100%;
  }
}

.sidebar__title {
  margin-top: 1.75em;
  margin-bottom: 1em;
}

.sidebar__file-row {
  vertical-align: top;
}

.sidebar__file-icon-column {
  padding-top: 5px;
  width: 1.5em;
}

.sidebar__file-icon {
  width: 1.4em;
}

.sidebar__file-title-column {
  padding-bottom: 5px;
}

.sidebar__file-title {
  color: $primary-dark;
  text-decoration: none;
  &:hover {
    @include underline(#fff);
  }
}