.page-intro {
  @include container(true);
  background-color: $light-secondary;
  position: relative;
}

.page-intro__title {
  color: $primary;
  max-width: 17em;
}

.page-intro__title--has-date {
  margin-bottom: 0.25em;
}

.page-intro__date {
  color: $primary-dark;
  margin-bottom: 1em;
}

.page-intro__inner {
  @include container(false);
  @include clearfix();
  padding-bottom: 2em;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-end;
  h1, h3 {
    @extend .page-intro__title;
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 54em) {
    display: block;
  }
}

.page-intro__text {
  width: 65%;
  margin-right: 5%;
  float: left;
  @media (max-width: 54em) {
    width: 100%;
    margin-bottom: 1em;
  }
}

.page-intro__text--no-padding {
  padding-top: 0;
}

.page-intro__image {
  width: 30%;
  float: left;
  margin: 0;
  @media (max-width: 60em) {
    width: 50%;
    float: inherit;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-intro__circle-graphic {
  width: 40em;
  height: 40em;
  position: absolute;
  left: -10em;
  top: -12em;
  background-image: url('../images/circle-graphic.svg');
  background-size: cover;
  pointer-events: none;
}