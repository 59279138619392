.small-archives {
  @include container(false);
  @include clearfix();
}

.small-archives__column {
  width: 20em;
  float: left;
  @media (max-width: 54em) {
    width: 100%;
  }
}

.small-archives__archive-title {
  color: $primary;
}

ul.small-archives__list {
  padding: 0;
  list-style: none;
  line-height: 1.5;
  width: 90%;
  @media (max-width: 54em) {
    width: 100%;
  }
}

li.small-archives__item {
  border-bottom: 1px solid $primary-dark;
  padding-top: .75em;
  padding-bottom: .75em;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  &:after {
    content: ">";
    color: $primary-dark;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}

.small-archives__link {
  color: $primary-dark;
  text-decoration: none;
  &:hover {
    @include underline(#fff);
  }
}

.small-archives__button {
  @include button();
}