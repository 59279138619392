.content {
  @include container(false);
  @include clearfix();
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
  h1, h2, h3 {
    color: $primary;
    a {
      color: $primary;
      text-decoration: none;
      &:hover {
        @include underline(#fff);
        &:focus {
          @include underline(#cef);
          color: $primary;
        }
      }
    }
  }
}

.content__inner, .content__archive-article__header {
  @extend .page-intro__text;
}

.content__archive-article__image {
  @extend .page-intro__image;
}

.content__archive-article {
  @include clearfix();
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid $primary-dark;
  &:last-child {
    border-bottom: none;
  }
}

.content__archive-title {
  font-size: 1.75em;
  margin-bottom: 0.25em;
}

.content__archive-date {
  color: $primary-dark;
  margin-bottom: 0.83em;
}

.content__archive-more {
  @include button();
  margin-top: .5em;
  margin-bottom: 1em;
  display: inline-block;
}