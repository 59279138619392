.contact__list {
  list-style: none;
  padding: 0;
}

.contact__item {
  @include clearfix;
  display: flex;
  align-items: center;
}

.contact__figure {
  margin: 0;
  width: 30%;
  float: left;
  text-align: center;
}

.contact__text {
  width: 70%;
  padding-left: 1em;
  float: left;
}

.contact__mail-adres {
  color: $primary-dark;
  text-decoration: none;
  margin-bottom: 1em;
  display: inline-block;
  &:hover {
    @include underline(#fff);
  }
}