.navigation {
  text-align: right;
  margin-left: auto;
  padding-top: 2.7em;
  @media (max-width: 66em) {
    padding-top: 1.5em;
  }
}

.navigation__toggle {
  display: none;
  position: absolute;
  z-index: 5;
  margin-top: 20px;
  width: 40px;
  height: 40px;
  right: 15px;
  opacity: 0;
  outline: none;
  cursor: pointer;
  @media (max-width: 54em) {
    display: block;
  }
}

.navigation__label {
  color: #fff;
  cursor: pointer;
  padding: .5em;
  display: none;
  margin-top: -0.75em;
  position: absolute;
  right: 1em;
  @media (max-width: 54em) {
    display: block;
    padding: 1em;
    top: 1em;
    right: 0;
  }
}

.navigation__toggle:checked ~ .navigation__menu {
    display: block;
}

.navigation__menu {
  padding: 0;
  list-style: none;
  @media (max-width: 54em) {
    display: none;
    position: absolute;
    z-index: 10;
    width: 100%;
    background: $primary-dark;
    left: 0;
    top: 3.825em;
  }

  @media (max-width: 33em) {
    top: 2.7em;
  }
}

.navigation__item {
  float: left;
  @media (max-width: 54em) {
    @include clearfix();
    float: none;
  }
}

.navigation__item--active {
  text-decoration: none;
}

.navigation__link {
  padding: 0.75em .5em;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: $primary;
  }
  @media (max-width: 54em) {
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 0.75em 1em;
  }
}

.navigation__link--active {
  background: rgba(255, 255, 255, .9);
  color: $primary;
}

.navigation__item--has-children {
  position: relative;
  &:hover, &.navigation__item--active {
    .navigation__menu--sub {
      display: flex;
    }
  }
}

.navigation__menu--sub {
  display: none;
  position: absolute;
  text-align: left;
  top: 2.59em;
  width: auto;
  align-items: flex-end;
  .navigation__link {
    color: $primary-dark;
    white-space: nowrap;
    background-color: #D0EEF9;
    &:hover {
      color: $primary;
    }
  }
  .navigation__link--active {
    color: $primary;
    text-decoration: underline;
  }

  @media (max-width: 54em) {
    position: relative;
    top: inherit !important;
    background-color: $secondary;
    display: block !important;
    .navigation__link {
      color: #fff;
      text-align: right;
    }
    .navigation__link--active {
      color: $primary;
    }
  }

}