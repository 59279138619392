.page-header {
  @include container(true);
  background-size: cover;
  background-position: center center;
  background-color: $light-secondary;
  min-height: 25em;
  display: flex;
  align-items: flex-end;
  position: relative;

  @media print {
    min-height: inherit;
    background-image: none !important;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(transparent, rgba(0,0,0,0.15));
    bottom: 0;
  }
}

.page-header__inner {
  @include container(false);
  color: #fff;
  display: flex;
  z-index: 1;
  margin-top: 10em;

  @media (min-width: 60em) {
    width: 100%;
  }
  @media print {
    color: $primary;
  }
}

.page-header__subtitle {
  margin-top: .5em;
  margin-right: .5em;
  font-size: 1.5em;
  line-height: 0.4;
  text-shadow: rgba(0,0,0,0.5) 0 0 20px;
}

.page-header__title {
  margin-top: 0;
  max-width: 11em;
  text-shadow: rgba(0,0,0,.5) 0 0 20px;
  @media (max-width: 60em) {
    font-size: 2.25em;
  }
  @media print {
    text-shadow: none;
  }
}

.page-header__button-group {
  margin-bottom: 2em;
}

.page-header__link {
  @include button();
  margin-right: 1em;
  @media (max-width: 60em) {
    display: inline-block;
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
}