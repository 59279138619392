.wf-active {
  font-family: 'vista-sans', sans-serif;
}

h1, h2, h3 {
  font-weight: 500;
}

h1 {
  font-size: 3em;
  margin: 0.67em 0;
}

h2 {
  font-size: 2.25em;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 0.25em;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  color: $primary-dark;
  margin-top: 0;
}

p a {
  @include underline(#fff);
}

.ie-lte-9 a {
  text-decoration: underline;
}

//.main ul {
//  padding-left: 1em;
//  margin: 0;
//}
//.main li {
//  margin: 0.25em 0;
//  padding-left: 0.3em;
//}