.header {
  width: 100%;
  min-height: 8.5em;
  background-image: url('../images/header_background.svg');
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  z-index: 20;
  background-size: 160em;
  margin-bottom: -2.5em;
  @media (max-width: 66em) {
    min-height: 7em;
    background-position: -50em -1.25em;
  }
  @media (max-width: 33em) {
    min-height: 6em;
    background-position: -52em -2.4em;
    margin-bottom: -2.4em;
  }
}

.header__container {
  padding-top: .5em;
  display: flex;
  @include container(false);
}

.header__logo {
  width: 15em;
  position: relative;
  z-index: 40;
  @media (max-width: 66em) {
    width: 12em;
  }
  @media (max-width: 33em) {
    width: 10em;
  }
}