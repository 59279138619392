.footer {
  width: 100%;
  min-height: 12em;
  margin-top: 5em;
  background-color: $primary-dark;
  background-image: url('../images/footer_background.svg');
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  background-size: 160em;
  display: flex;
  align-items: center;
}

.footer__container {
  padding-top: 1.5em;
  display: flex;
  @include container(false);
}

.footer__brands {
  margin: 0 auto;
  text-align: center;
}

.footer__brands-title {
  color: #fff;
  font-weight: normal;
}

.footer_brands-logos {
  display: flex;
  align-items: center;
}

.footer_brand--middle {
  margin-left: 2em;
  margin-right: 2em;
}