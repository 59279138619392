@mixin container($wide) {
  @if $wide {
    width: 100%;
    max-width: 80em;
  } @else {
    width: 90%;
    max-width: 60em;
  }
  margin-left: auto;
  margin-right: auto;
}

@mixin underline($background) {
  text-decoration: none;
  text-shadow: 0.03em 0 $background, -0.03em 0 $background,
  0 0.03em $background, 0 -0.03em $background,
  0.06em 0 $background, -0.06em 0 $background,
  0.09em 0 $background, -0.09em 0 $background,
  0.12em 0 $background, -0.12em 0 $background,
  0.15em 0 $background, -0.15em 0 $background;
  background-image: linear-gradient($background, $background), linear-gradient($background, $background), linear-gradient(currentColor, currentCOlor);
  background-size: .05em 0.1em, .05em 0.1em, 0.2em 0.1em;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: 0 91%, 100% 91%, 0 100%;
  &:focus {
    outline: none;
    background-color: #cef;
  }
}

@mixin button() {
  background-color: $primary;
  color: #fff;
  text-decoration: none;
  margin-left: .5em;
  padding-top: .25em;
  padding-bottom: .25em;
  position: relative;
  &:hover {
    @include underline($primary);
    background-position: 0 91%, 100% 91%, 0 85%;
    &:focus {
      @include underline(#cef);
      color: $primary;
      &:before {
        background-color: #cef;
      }
    }
  }
  &:before {
    content: "";
    width: calc(100% + 1em);
    height: 100%;
    position: absolute;
    top: 0;
    left: -0.5em;
    z-index: -1;
    background-color: $primary;
  }
  &:after {
    content: ">";
    margin-left: .5em;
  }

}

@mixin clearfix {
  &:before {
    content: "";
    display: table;
    clear: both;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}