.pagination__list {
  display: inline-block;
  padding: 0;
  margin-top: 1em;
}

.pagination__list-item {
  display: inline;
}

.pagination__item {
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  color: $primary-dark;
  border: 1px solid;
  margin-right: -1px;
  &:hover {
    color: $secondary;
  }
}

.pagination__item--current {
  background-color: $primary-dark;
  color: #fff;
  border-color: $primary-dark;
  &:hover {
    color: #fff;
  }
}

.pagination__item--inactive {
  opacity: .5;
  &:hover {
    color: $primary-dark;
  }
}