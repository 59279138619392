.breadcrumbs {
  @include container(true);
  @include clearfix;
  position: relative;
  z-index: 1;
  padding-top: 2em;
  background-color: $light-secondary;
}

.breadcrumbs__list {
  @include container(false);
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.breadcrumbs__list-item {
  display: inline;
  font-size: .9em;
  &:after {
    content: " \276F ";
    color: $primary-dark;
    padding: .5em;
    font-size: .75em;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}

.breadcrumbs__link {
  text-decoration: none;
  color: $primary-dark;
  &:hover {
    @include underline(#F3FBFE);
  }
}